/* Login Styles and Register */

body {
  font-family: 'Poppins', sans-serif !important;
  overflow: auto !important;
}
.bimg {
  background-image: url('../public/background.jpg');
}
.container-fluid {
  background-size: cover;
  background-position: 45%;
  width: 100%;
}
.addateamlead {
  gap: 5px;
  display: flex;
  margin-left: 160px;
}
.dragdropcontainer {
  margin-right: 0px !important;
}

.user-task-details {
  margin-left: 290px !important;
}
.taskdetailspage {
  margin-left: 290px !important;
}
.content .mlth {
  margin-left: 200px;
}

/* .tikcetraiseform{
  margin-left: 220px !important;
} */

.icons {
  position: absolute;
  right: 0;
  z-index: 10;
  height: 30%;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
}
.LoginSection {
  width: 70%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0);

  padding-bottom: 10%;
}
.registerSection {
  width: 70%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0);
  margin-top: 10%;
}

h2 {
  font-size: 160px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.LoginSection h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.form-label {
  font-weight: bold;
  font-family: Poppins;
}

.form-control {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-family: Poppins;
}

.form-check-input {
  margin-right: 10px;
}

.archivebtn {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.archivebtn button {
  background-color: gray;
  color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.archivebtn button:hover {
  background-color: #0056b3;
}
.archivetaskbtn button:hover {
  background-color: #0056b3;
}
.archivetaskbtn {
  margin-bottom: 10px !important;
}

.archivetaskbtn button {
  margin: 10px 10px;
  background-color: gray;
  color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.loginbtn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: black;
  color: #fff;
  cursor: pointer;
}

p {
  padding-top: 8px;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

p a {
  text-decoration: none;
  color: #000;
}

p a:hover {
  text-decoration: underline;
}

.forgotpass {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins;
}
.forgotpass a {
  text-decoration: none;
  color: #000;
}
.homeimg {
  margin-top: 14%;
  margin-bottom: 30%;
  border-radius: 10px;
  margin-left: 25%;
}
.loginimg {
  margin-bottom: 30%;
  width: 100%;
  border-radius: 10px;
}
.registerimg {
  margin-top: 10%;
  margin-bottom: 30%;
  border-radius: 10px;

  width: 100%;
}

/*  Styles */
.HomeSection {
  width: 80%;
  padding: 30px 40px;
  max-width: 600px;
  margin: 100px auto;
  border-radius: 30px;
  box-shadow: 0px 4px 42.2px 0px rgba(131, 83, 0, 0.08);
}

.HomeSection h2 {
  font-family: 'Familjen Grotesk';
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding-bottom: 50px;
}

.HomeSection select,
.HomeSection input,
.HomeSection button {
  width: 100%;
  height: 48px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #eaf8f4;
  background: #fff;
  padding-left: 20px;
  box-shadow: 0px 5px 4px 0px rgba(24, 78, 182, 0.1);
}

.HomeSection button {
  background-color: #261a09;
  color: white;
  border: none;
  cursor: pointer;
}

.HomeSection button:hover {
  border-radius: 8px;
  background: linear-gradient(90deg, #261a09 0%, #935c0a 100%);
  transition: 0.3s ease;
}
.custom-file-label {
  padding: 0px !important;
}
input[type='file']::file-selector-button {
  margin-right: 20px;
  padding: 10px 24px;
  width: 182px;
  border-radius: 8px;
  background: #f4d9af;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  background: #eaf8f4;
}

/* Task item styles */
.TaskSectionH2 {
  font-family: 'Familjen Grotesk';
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  /* border-bottom: 1px solid #ddd; */
}

.task-item ul {
  list-style-type: none;
}
.task-item li {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

.task-item select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-6 {
  flex: 1;
  min-width: 300px;
}

.FlatDataSection {
  margin-top: 20px;
  background-color: white;
}

.flatdataimg {
  max-width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.admin-layout {
  display: flex;
  /* height: 100vh; */
  background-color: white;
}

.sidebar .logo {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 24px;
}

.sidebar .nav {
  display: block;
  list-style: none;
  padding-top: 64px;
  margin: 0;
}

.sidebar .nav li {
  display: flex !important;
  height: 55px !important;
  padding: 0px 10px 23px 0px !important;
  align-items: center !important;
  gap: 10px !important;
  font-family: 'DM Sans' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.nav li {
  list-style: none;
}

.nav li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  background-color: #163365; /* Default background */
  border-left: 5px solid transparent; /* Default border */
  transition: background-color 0.3s, border-left 0.3s;
  font-size: 18px; /* Adjust text size as needed */
  display: flex;
  align-items: center;
}

.nav li a:hover {
  background-color: #4b648d;
  border-left: 5px solid white;
}
.sidebar .nav {
  display: block !important;
  list-style: none !important;
  /* padding-left: 7px !important; */
  padding-top: 15px !important;
  /* margin: 10px !important; */
}
.sidebar .nav a {
  text-decoration: none !important;
  color: #fff !important;
  display: flex !important;
  margin-left: -5px !important;
  flex-direction: row !important;
  width: 100% !important;
  text-align: center !important;
  align-items: center !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.sidebar .nav li {
  display: flex !important;
  height: 40px !important;
  padding: 0px 0px 0px 0px !important;
  align-items: center !important;
  gap: 10px !important;
  font-family: 'DM Sans' !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.Userwrapper h2 {
  padding: 15px 0;
  font-size: 22px;
}

.content {
  flex: 1;
  /* padding: 30px; */
  overflow: auto;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AdminPage {
  font-family: 'Roboto', sans-serif;
}

/* Header Section */
.header {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #d1d5db; /* Light grey background */
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-right: 10px;
  border: 1px solid #000;
}

.user-name {
  font-size: 16px;
  font-weight: 500;
}

.header-right {
  display: flex;
  align-items: center;
}

.notification-icon {
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.current-time {
  font-size: 16px;
  font-weight: 400;
}

.AdminUsersSection h3 {
  font-family: 'Roboto Slab', serif;

  margin-bottom: 30px;
  margin-top: 0;
  font-weight: 500;
}
.AdminUsers input::placeholder {
  color: black;
}

.AdminUsers input {
  /* width: 337px;
  border: 1px solid #000;
  cursor: pointer; */
}
.AdminUsers input::placeholder {
  /* padding-left: 8px; */
}
.AdminUsers span {
  /* position: absolute; */
  color: #464646 !important;
  opacity: 60%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0px !important;
}

/* .material-symbols-outlined {
  width: 24px;
  height: 24px;
  cursor: pointer;
} */
.AdminUsersSection {
  padding: 0px !important;
}
.AdminUsers {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* display: flex;
  width: 337px;
  height: 44px;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px; */
}
.search-container {
  width: 452px;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  padding: 13px 0px 13px 20px;
  border-radius: 5px;
  background-color: #fff;
}

.search-container .material-symbols-outlined {
  margin-right: 8px;
  color: #000;
}

.search-input {
  color: #464646;
  opacity: 60%;
  border: none;
  outline: none;
  font-size: 16px;
  width: 100px;
}
.archived-button {
  background-color: #1f3b5d; /* Dark blue color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.archived-button:hover {
  background-color: #f26836; /* Slightly lighter blue for hover effect */
}
/* table {
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

}

.tableFixHead {
  overflow: auto;

}
table.tableFixHead thead th {
  position: sticky;
  top: -1px ;
  padding-top: 0px;
  margin: 0px;
}


table th,
table td {
  padding: 10px;
  text-align: left;
}

table tbody tr:nth-child(even) {
  background-color: rgba(0, 115, 151, 0.03);
}

table th {
  background-color: #d4eff8;
}

table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table tbody tr:hover {
  background-color: rgba(0, 115, 151, 0.03);
} */
.AdminTaskToUserSection h3 {
  margin-bottom: 30px;
  margin-top: 0;
}

.AdminRoleFormSection h3 {
  margin-bottom: 30px;
  margin-top: 0;
}
.AdminTaskToUserForm {
  margin-top: 0;
}
.AdminTaskToUserForm label {
  padding-bottom: 10px;
}
.AdminTaskToUserForm button {
  margin-bottom: 20px;
  background-color: rgba(0, 115, 151, 0.03);
  border-radius: 8px;
}
.admin-layout a {
  text-decoration: none;
}

/* Container styles */
.ForgotPasswordSection {
  width: 10%;
  max-width: 400px;
  margin: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}
.forgotpassCss {
  width: 50%;
  margin-left: 25%;
}
/* Form wrapper styles */
.forgotpassCss {
  text-align: center;
}

.forgotpassCss h2 {
  margin-bottom: 20px;
  color: #333;
}

.forgotpassCss input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.forgotpassCss button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgotpassCss button:hover {
  background-color: #218838;
}

.message {
  margin-top: 15px;
  color: #555;
}

/* .date-picker {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 8px;
} */

.date-picker:focus {
  border-color: #007bff;
  outline: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.logoutbtn {
  color: white !important;
}

/* .react-datepicker__input-container {
  width: 100%;
} */

/* .navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: end;
  column-gap: 1rem;
}
.navbar{
  position: fixed;
  width: 100%;
} */

.admin-layout {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #163365;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}

.content {
  flex: 1;
  /* padding: 20px; */
}

.nav {
  list-style-type: none;
  padding: 0;
}

.nav li {
  margin-bottom: 10px;
}

.nav a {
  text-decoration: none;
  color: #000;
}

/* .nav a.active {
  color: #000;

} */

.logout-container {
  margin-top: 200px;
  /* margin-left:-12% ; */
  padding-left: 30px;
}

.btn {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: #1a1919;
  border: none;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  margin-left: -3.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain; /* Maintain aspect ratio and fit within the box */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 60px;
  cursor: pointer;
  color: white;
}

.secprojname {
  width: 370px;
  word-wrap: break-word;
}
.badge {
  padding: 15px;
  margin-left: 30px;
}

.list-group-item .badge {
  padding: 5px;
  margin: 0px;
  width: 200px;
}

.cssforoption {
  color: #0056b3;
}

/* GroupDoTo.css */

.modal-body-custom {
  display: flex;
  flex-direction: column;
  height: 500px; /* Adjust the height as needed */
}

.user-list-container {
  flex: 1;
  overflow-y: auto; /* Allows scrolling when content overflows */
  margin-top: 10px;
}
.unread-todo {
  background-color: yellow; /* Light AliceBlue background for unread todos */
  border-left: 5px solid #007bff; /* Blue left border for visual emphasis */
  font-weight: bold; /* Bold text for unread todos */
}

/* In your CSS file or within a <style> tag */
.embed-responsive {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dropdowntodo {
  margin-right: 10px;
}

.table-container {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
}
.Add_Project {
  background-color: #1f3b5d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
.Add_Project:hover {
  background-color: #f26836;
}
.AddMembers {
  background-color: #1f3b5d;
  margin-left: 15px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
.closebutton {
  background-color: #1f3b5d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.mt-4 .container {
  margin: 50px;
}
.nextprofile {
  background-color: #1f3b5d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
.ExtendDeadline {
  background-color: #f26836;
  color: #fff;
  border: none;
  margin-top: 10px;
  /* margin: 10px; */
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.removemembers {
  margin: 30px 0px 0px 0px;
  padding: 0px 0px 0px 65px;
}

.ml-2 {
  margin-left: 10px !important;
  text-align: left;
  font-size: 18px !important;
}
.removemember {
  margin: 0px 0px 20px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}
.AllProjectList {
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
}
.projectdetails {
  font-size: 36px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}
.teammembers {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.detailsproject {
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
  margin-top: 15px;
}
.ProjectsList {
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
  margin-top: 15px;
}
.projectname {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
  margin: 0px;
}
.color-badge {
  height: 30px;
  padding: 10px !important;
}
.list-group {
  border: 1px solid #163365;
  /* align-items: center; */
}
.list-group-item {
  font-size: 16px;
  font-family: 'Roboto' sans-serif;
  /* height: 45px; */
  padding: 10px 20px;
  border-bottom: 1px solid #163365 !important;
}
.list-group-items {
  font-size: 16px;
  font-family: 'Roboto' sans-serif;
  height: 45px;
  padding: 10px 20px;
}
/* Task Table Header */
.task-table-header {
  border-bottom: 2px solid #ddd;
  margin-bottom: 10px;
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
  background-color: #e6e6e6;
}

/* Each header column */
.task-table-column {
  flex: 1;
  text-align: center;
}

/* Task Table List */
.task-table {
  list-style-type: none;
  padding: 0;
}

/* Task Table Rows */
.task-table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  align-items: center;
  /* Background color for rows */
}

.task-table-cell {
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #000 !important;
  flex: 1;
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
}
.custom-link {
  color: #000;
  text-decoration: none;
}
.inline-table {
  minwidth: '200px';
  maxwidth: '200px';
  overflow: 'auto';
}
.task-created-badge {
  font-size: 18px;
  font-weight: 400;
  color: #000 !important;
  flex: 1;
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
}
.readmoretask {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #163365;
  padding: 0px 0px;
  height: 40px;
  width: 100px;
  border-radius: 4px;
}
.readmoretask:hover {
  background-color: #f26836;
  border: none;
}
.btn:hover {
  background-color: #f26836;
}
.assigntask {
  font-family: 'Roboto Slab', serif;
  margin-bottom: 30px;
  font-weight: 500;
  margin-left: 50px;
  margin-top: 20px;
}
.inline-table12 {
  minwidth: '120px';
  maxwidth: '120px';
  overflow: 'auto';
}
.shared {
  margin-top: 30px;
}
.bigmb-4 {
  margin: auto !important;
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .AdminUsersSection {
    padding: 20px 20px 0px 0px;
  }
  .tableFixHead2 {
    margin: 20px 10px 20px 0px !important;
    border: none;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  margin: 0 10px;
}

/* .contentmlth{
  margin-left: 200px;
} */

@media (max-width: 600px) {
  .react-datepicker {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: flex;
    position: relative;
    line-height: initial;
  }

  td {
    padding: 5px !important;
  }
  .mobmar {
    margin-left: -24px !important;
  }
  .contentprofile {
    margin-top: 20px;
    width: 90% !important;
  }
  .filler {
    padding: 0px !important;
    margin: 0px !important;
  }
  .bigmb-4 {
    margin: 0px !important;
  }
  .contentmlth {
    margin-left: 10px !important;
  }
  .shared h2 {
    margin-top: 60px !important;
    margin-left: 0px !important;
  }
  .removemembers {
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 5px;
  }
  .custom-btn {
    font-size: 14px !important;
    width: 50%;
  }
  .p-4 {
    padding: 1.5rem !important;
    margin: -0px !important;
  }
  .mobSize {
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 1rem;
  }
  .registerSection {
    width: 100%;
  }
  .inline-table12 {
    minwidth: '70px' !important;
    maxwidth: '70px' !important;
    overflow: 'auto' !important;
  }

  .inline-table {
    minwidth: '100px' !important;
    maxwidth: '100px' !important;
    overflow: 'auto';
  }
  .logo {
    margin-top: 50px !important;
  }
  .accordion {
    width: 100%;
  }
  .assigntask {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 15px !important;
    margin-top: 25px !important;
    font-size: 24px !important;
    font-weight: 500;
    margin-left: 30px !important;
  }
  .readmoretask {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #fff;
    background-color: #163365;
    padding: 1px 5px;
    height: 32px;
    width: 60px;
    border-radius: 4px;
  }
  .logout-container {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: center;
  }
  .mb-4 {
    margin: 0.75rem 0.3rem 0.3rem 2.2rem !important;
  }
  h6 {
    margin-left: 20px !important ;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 12px !important;
  }
  h6 {
    margin-left: 20px !important ;
  }
  .task-table-row {
    display: flex;
    justify-content: end;
    padding: 6px 0;
    margin: 6px -60px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
  .task-table-cell,
  .task-created-badge {
    font-size: 14px !important;
  }
  .task-table-column {
    font-size: 0.8rem;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    margin: 10px;
  }
  .teammembers {
    margin: 4px;
  }
  .projectname {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
  }
  h5 .task-table-column {
    font-size: 0.75rem !important;
  }
  .task-table-header {
    width: 110%;
    padding: 0px 0px;
    font-weight: bold;
    text-align: center;
    background-color: #e6e6e6;
  }
  .table-container {
    margin-top: 0px !important;
    margin-left: -20px !important;
  }
  .AdminUsersSection {
    padding: 5px 0px 0px 15px;
  }
  .search-input {
    width: 100px;
  }
  .removemember {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removemember .fa-user-minus {
    color: white;
  }

  .removemember span {
    display: none; /* Hide text on small screens */
  }
  .ExtendDeadline {
    width: 90% !important;
    margin: 10px;
  }
  .d-flexu .downstream {
    display: flex !important;
    flex-direction: column !important;
  }
  .AddMembers {
    background-color: green; /* Green color for small screens */
    width: 40px; /* Make it circular */
    height: 40px;
    margin-left: 160px;
    margin-top: -30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AddMembers span {
    display: none; /* Hide the text on small screens */
  }

  .AddMembers .fa-user-plus {
    color: white; /* White icon for contrast */
  }

  .Add_Project {
    width: 100%;
  }
  .color-badge {
    height: 20px !important;
    padding: 4px !important;
  }
  .badge {
    padding: 10px;
    margin: -1px 4px -4px 4px;
  }
  .ml-2 {
    margin: 0px !important;
    text-align: justify;
    font-size: 14px !important;
  }
  .sidebar {
    width: 50px !important;
    height: 100% !important;
    padding: 15px !important;
  }
  .sidebar.open {
    height: 100% !important;
    width: 200px !important; /* Expandable width on small screens when open */
  }
  .col-7 {
    width: 100% !important;
  }
  .loginimg {
    display: none !important; /* Hide the image */
  }
  .registerimg {
    display: none !important;
  }

  .mx-3 {
    padding: 0px;
    width: 50%;
  }
  .mp-4 {
    margin: 0px !important;
  }
  .list-group-item .badge {
    padding: 5px !important;
    margin: 5px !important;
    width: 140px !important;
  }

  .search-input {
    width: 100%; /* Ensure full width */
    padding: 5px 10px;
    border: none;
    outline: none !important;
    color: black;
  }

  .search-input:focus {
    border: 1px solid #007bff; /* Optional: Highlight the border when focused */
  }

  .material-symbols-outlined {
    margin-right: 10px; /* Add spacing between the icon and input */
    font-size: 20px;
  }

  .reports {
    margin-top: 0px !important;
  }

  .logout-container {
    padding-left: 0px !important;
    margin-left: -20px !important;
    margin-top: 75px !important;
  }
  .archived-button {
    margin-left: 100px !important;
    background-color: #1f3b5d !important; /* Dark blue color */
    color: #fff !important;
    border: none !important;
    padding: 5px 5px;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 11px !important;
    cursor: pointer !important;
  }

  .search-container {
    width: 150px !important;
    height: 25px !important;
    margin-left: -10px !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid #000 !important;
    padding: 12px 0px 12px 7px !important;
    border-radius: 5px !important;
    background-color: #fff !important;
  }
  .AdminUsers {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }
  .nav li a {
    display: block !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: white !important;
    background-color: #163365 !important; /* Default background */
    border-left: 5px solid transparent !important; /* Default border */
    transition: background-color 0.3s, border-left 0.3s !important;
    font-size: 18px; /* Adjust text size as needed */
    /* display: flex; */
    align-items: center !important;
  }

  .header {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    background-color: #d1d5db !important; /* Light grey background */
    padding: 0px 5px !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
  }
  .AdminUsersSection h3 {
    margin-left: -40px !important;
    margin-bottom: 24px !important;
    font-family: 'Roboto Slab', serif !important;
    /* margin-bottom: 30px; */
    margin-top: 0 !important;
    text-align: center !important;
  }
  .sidebar .nav {
    display: block !important;
    list-style: none !important;
    /* padding-left: 7px !important; */
    padding-top: 15px !important;
    /* margin: 10px !important; */
  }
  .sidebar .nav a {
    text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    margin-left: -5px !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .sidebar .nav li {
    display: flex !important;
    height: 40px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center !important;
    gap: 10px !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .task-item {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .task-item ul {
    margin-bottom: 10px !important;
  }

  .task-item select {
    width: 100% !important;
  }
}

@media (max-width: 750px) {
  .react-datepicker {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: flex;
    position: relative;
    line-height: initial;
  }
  td {
    padding: 5px !important;
  }
  .mobmar {
    margin-left: -24px !important;
  }
  .contentprofile {
    margin-top: 20px;
    width: 90% !important;
  }
  .filler {
    padding: 0px !important;
    margin: 0px !important;
  }
  .bigmb-4 {
    margin: 0px !important;
  }
  .contentmlth {
    margin-left: 10px !important;
  }
  .mx-auton {
    margin: 0px !important;
  }
  .share {
    margin-top: 60px !important;
    margin-left: 0px !important;
  }
  .removemembers {
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 5px;
  }
  .custom-btn {
    font-size: 14px !important;
    width: 50%;
  }
  .p-4 {
    padding: 1.5rem !important;
    margin: -0px !important;
  }
  .mobSize {
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 1rem;
  }
  .registerSection {
    width: 100%;
  }
  .inline-table12 {
    minwidth: '70px' !important;
    maxwidth: '70px' !important;
    overflow: 'auto' !important;
  }
  .inline-table {
    minwidth: '100px' !important;
    maxwidth: '100px' !important;
    overflow: 'auto';
  }
  .logo {
    margin-top: 50px !important;
  }
  .assigntask {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 15px !important;
    margin-top: 25px !important;
    font-size: 24px !important;
    font-weight: 500;
    margin-left: 30px !important;
  }
  .readmoretask {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #fff;
    background-color: #163365;
    padding: 1px 5px;
    height: 32px;
    width: 60px;
    border-radius: 4px;
  }
  .sidebar .nav {
    display: block !important;
    list-style: none !important;
    /* padding-left: 7px !important; */
    padding-top: 15px !important;
    /* margin: 10px !important; */
  }
  .sidebar .nav a {
    text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    margin-left: -5px !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .sidebar .nav li {
    display: flex !important;
    height: 40px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center !important;
    gap: 10px !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .logout-container {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: center;
  }
  h6 {
    margin-left: 20px !important ;
  }
  h2 {
    font-size: 28px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  h4 {
    font-size: 18px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  .task-table-row {
    display: flex;
    justify-content: end;
    padding: 6px 0;
    margin: 6px -60px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
  .task-table-cell,
  .task-created-badge {
    font-size: 14px !important;
  }
  .task-table-column {
    font-size: 0.8rem;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    margin: 10px;
  }
  .teammembers {
    margin: 4px;
  }
  .projectname {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
  }
  h5 .task-table-column {
    font-size: 0.75rem !important;
  }
  .task-table-header {
    width: 110%;
    padding: 0px 0px;
    font-weight: bold;
    text-align: center;
    background-color: #e6e6e6;
  }
  .table-container {
    margin-top: 0px !important;
    margin-left: -20px !important;
  }
  .AdminUsersSection {
    padding: 5px 0px 0px 15px;
  }
  .search-input {
    width: 100px;
  }
  .removemember {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removemember .fa-user-minus {
    color: white;
  }

  .removemember span {
    display: none; /* Hide text on small screens */
  }
  .ExtendDeadline {
    width: 90% !important;
    margin: 10px;
  }
  .d-flexu .downstream {
    display: flex !important;
    flex-direction: column !important;
  }
  .AddMembers {
    margin-left: 160px;
    margin-top: -30px;
    background-color: green; /* Green color for small screens */
    width: 40px; /* Make it circular */
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AddMembers span {
    display: none; /* Hide the text on small screens */
  }

  .AddMembers .fa-user-plus {
    color: white; /* White icon for contrast */
  }

  .Add_Project {
    width: 100%;
  }
  .color-badge {
    height: 20px !important;
    padding: 4px !important;
  }
  .badge {
    padding: 10px;
    margin-left: -75px;
  }
  .ml-2 {
    margin: 0px !important;
    text-align: justify;
    font-size: 14px !important;
  }
  .sidebar {
    height: 100% !important;
    width: 60px !important;
    padding: 15px !important;
  }
  .sidebar.open {
    height: 100% !important;
    width: 200px !important; /* Expandable width on small screens when open */
  }
  .col-7 {
    width: 100% !important;
  }
  .loginimg {
    display: none !important; /* Hide the image */
  }
  .registerimg {
    display: none !important;
  }
  .mx-3 {
    padding: 0px;
    width: 50%;
  }
  .mp-4 {
    margin: 0px !important;
  }
  .list-group-item .badge {
    padding: 5px !important;
    margin: 5px !important;
    width: 140px !important;
  }
  .mb-4 {
    margin: 0.75rem 0.3rem 0.3rem 2.2rem !important;
  }
  .reports {
    margin-top: 0px !important;
  }
  .logout-container {
    margin-left: -20px !important;
    padding-left: 0px !important;
    margin-top: 75px !important;
  }
  .archived-button {
    margin-left: 100px !important;
    background-color: #1f3b5d !important; /* Dark blue color */
    color: #fff !important;
    border: none !important;
    padding: 5px 5px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 11px !important;
    cursor: pointer !important;
  }

  .search-container {
    width: 150px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid #000 !important;
    padding: 12px 0px 12px 7px !important;
    border-radius: 5px !important;
    background-color: #fff !important;
  }
  .AdminUsers {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }
  .nav li a {
    display: block !important;
    padding: 0px 5px !important;
    text-decoration: none !important;
    color: white !important;
    background-color: #163365 !important; /* Default background */
    border-left: 5px solid transparent !important; /* Default border */
    transition: background-color 0.3s, border-left 0.3s !important;
    font-size: 18px !important; /* Adjust text size as needed */
    /* display: flex!important; */
    align-items: center !important;
  }

  .header {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    background-color: #d1d5db !important; /* Light grey background */
    padding: 0px 3px !important;
    border-radius: 5px !important;
  }

  .HomeSection {
    width: 90% !important;
    padding: 15px !important;
    max-width: none !important;
    margin: 40px auto !important;
  }

  .HomeSection h2 {
    font-size: 30px !important;
  }

  .col-6 {
    min-width: 100% !important;
  }
  .nav li a {
    display: block;
    padding: 0px 5px;
    text-decoration: none;
    color: white;
    background-color: #163365; /* Default background */
    border-left: 5px solid transparent; /* Default border */
    transition: background-color 0.3s, border-left 0.3s;
    font-size: 18px; /* Adjust text size as needed */
    /* display: flex; */
    align-items: center;
  }

  .container {
    width: 80%;
  }

  input {
    width: 90%;
    max-width: none;
  }

  button {
    width: 30%;
    max-width: none;
  }
  .header {
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #d1d5db; /* Light grey background */
    /* padding: 0px 5px; */
    border-radius: 5px;
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .react-datepicker {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: flex;
    position: relative;
    line-height: initial;
  }
  td {
    padding: 5px !important;
  }
  .mobmar {
    margin-left: -24px !important;
  }
  .contentprofile {
    margin-top: 20px;
    width: 90% !important;
  }
  .filler {
    padding: 0px !important;
    margin: 0px !important;
  }
  .bigmb-4 {
    margin: 0px !important;
  }
  .contentmlth {
    margin-left: 10px !important;
  }
  .visibletodo {
    margin-top: 30px;
  }
  h2.share {
    margin-top: 60px !important;
    margin-left: 0px !important;
  }
  .removemembers {
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 5px;
  }
  .custom-btn {
    font-size: 14px !important;
    width: 50%;
  }
  .p-4 {
    padding: 1.5rem !important;
    margin: 0px !important;
  }
  .mobSize {
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 1rem;
  }
  .registerSection {
    width: 100%;
  }
  .inline-table12 {
    minwidth: '70px' !important;
    maxwidth: '70px' !important;
    overflow: 'auto' !important;
  }
  .inline-table {
    minwidth: '100px' !important;
    maxwidth: '100px' !important;
    overflow: 'auto';
  }
  .logo {
    margin-top: 50px !important;
  }
  .accordion {
    width: 105%;
  }
  .assigntask {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 15px !important;
    margin-top: 25px !important;
    font-size: 24px !important;
    font-weight: 500;
    margin-left: 30px !important;
  }
  .readmoretask {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #fff;
    background-color: #163365;
    padding: 1px 5px;
    height: 32px;
    width: 55px;
    border-radius: 4px;
  }
  .sidebar .nav {
    display: block !important;
    list-style: none !important;
    /* padding-left: 7px !important; */
    padding-top: 15px !important;
    /* margin: 10px !important; */
  }
  .sidebar .nav a {
    text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    margin-left: -5px !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .sidebar .nav li {
    display: flex !important;
    height: 40px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center !important;
    gap: 10px !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  /*.sidebar .nav a {
     text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    margin-left:  -5px !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
} */
  .logout-container {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: center;
  }
  h6 {
    margin-left: 10px !important ;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 12px !important;
  }
  .task-table-row {
    display: flex;
    justify-content: space-around;
    padding: 0px 0;
    margin: 15px -55px;
    border-bottom: 1px solid #ddd;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
  }
  .task-table-cell,
  .task-created-badge {
    font-size: 14px !important;
  }
  .task-table-column {
    font-size: 0.8rem;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    margin: 10px;
  }
  .teammembers {
    margin: 4px;
  }
  .projectname {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
  }
  h5 .task-table-column {
    font-size: 0.75rem !important;
  }
  .task-table-header {
    width: 110%;
    padding: 0px 0px;
    font-weight: bold;
    text-align: center;
    background-color: #e6e6e6;
  }
  .table-container {
    margin-top: 0px !important;
    margin-left: -20px !important;
  }
  .AdminUsersSection {
    padding: 5px 0px 0px 15px;
  }
  .search-input {
    width: 100px;
  }
  .removemember {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removemember .fa-user-minus {
    color: white;
  }

  .removemember span {
    display: none; /* Hide text on small screens */
  }
  .ExtendDeadline {
    width: 90% !important;
    margin: 10px;
  }
  .d-flexu .downstream {
    display: flex !important;
    flex-direction: column !important;
  }
  .AddMembers {
    margin-left: 160px;
    margin-top: -30px;
    background-color: green; /* Green color for small screens */
    width: 40px; /* Make it circular */
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AddMembers span {
    display: none; /* Hide the text on small screens */
  }

  .AddMembers .fa-user-plus {
    color: white; /* White icon for contrast */
  }

  .Add_Project {
    width: 100%;
  }
  .color-badge {
    height: 20px !important;
    padding: 4px !important;
  }

  .badge {
    padding: 10px;
    margin: 2px 0px 2px -73px;
  }
  .ml-2 {
    margin: 0px !important;
    text-align: justify;
    font-size: 14px !important;
  }
  .sidebar {
    width: 50px !important;
    height: 100% !important;
    padding: 15px !important;
  }
  .sidebar.open {
    height: 100% !important;
    width: 200px !important; /* Expandable width on small screens when open */
  }
  .col-7 {
    width: 100% !important;
  }
  .loginimg {
    display: none !important; /* Hide the image */
  }
  .registerimg {
    display: none !important;
  }
  .mx-3 {
    padding: 0px;
    width: 50%;
  }
  .header-left {
    /* remove all the overflow properties */
    /* make this change for universal */
    /* make button in 30%  */
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #d1d5db; /* Light grey background */
    padding: 0px 5px;
    border-radius: 5px;
    margin: 5px 5px;
  }
  .mp-4 {
    margin: 0px !important;
  }
  .list-group-item .badge {
    padding: 5px !important;
    margin: 5px !important;
    width: 140px !important;
  }
  .mb-4 {
    margin: 0.75rem 0.3rem 0.3rem 2.2rem !important;
  }

  .reports {
    margin-top: 0px !important;
  }
  .logout-container {
    margin-left: -20px !important;
    padding-left: 0px !important;
    margin-top: 75px !important;
  }
  .archived-button {
    margin-left: 100px !important;
    background-color: #1f3b5d !important; /* Dark blue color */
    color: #fff !important;
    border: none !important;
    padding: 5px 5px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 11px !important;
    cursor: pointer !important;
  }

  .search-container {
    width: 150px !important;
    height: 30px !important;
    margin-left: -20px !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid #000 !important;
    padding: 12px 0px 12px 7px !important;
    border-radius: 5px !important;
    background-color: #fff !important;
  }
  .AdminUsers {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }

  .header {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    background-color: #d1d5db !important; /* Light grey background */
    padding: 0px 5px !important;
    border-radius: 5px !important;
  }
  .AdminUsersSection h3 {
    margin-left: -40px !important;
    margin-bottom: 24px !important;
    font-family: 'Roboto Slab', serif !important;
    /* margin-bottom: 30px!important; */
    margin-top: 0 !important;
    text-align: center !important;
  }
  /*.sidebar .nav {
    display: block!important;
    list-style: none!important;
    /* padding-left: 7px !important; 
    padding-top: 15px!important;
    /* margin: 10px!important; 
}*/
  .ForgotPasswordSection {
    margin: 10px !important;
    padding: 15px !important;
  }

  .forgotpassCss h2 {
    font-size: 22px !important;
  }

  .forgotpassCss input {
    font-size: 14px !important;
  }

  .forgotpassCss button {
    font-size: 14px !important;
  }
  .nav li a {
    display: block !important;
    padding: 0px 0px !important;
    text-decoration: none !important;
    color: white !important;
    background-color: #163365 !important; /* Default background */
    border-left: 5px solid transparent !important; /* Default border */
    transition: background-color 0.3s, border-left 0.3s !important;
    font-size: 16px !important; /* Adjust text size as needed */
    align-items: center !important;
  }
}

@media (max-width: 320px) {
  .react-datepicker {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: flex;
    position: relative;
    line-height: initial;
  }
  td {
    padding: 5px !important;
  }
  .mobmar {
    margin-left: -20px !important;
  }
  .contentprofile {
    margin-top: 20px;
    width: 90% !important;
  }
  .filler {
    padding: 0px !important;
    margin: 0px !important;
  }
  .bigmb-4 {
    margin: 0px !important;
  }
  .contentmlth {
    margin-left: 10px !important;
  }
  .shared {
    margin-top: 60px !important;
    margin-left: 0px !important;
  }
  .removemembers {
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 5px;
  }
  .custom-btn {
    font-size: 14px !important;
    width: 50%;
  }
  .p-4 {
    padding: 1.5rem !important;
    margin: 0px !important;
  }
  .mobSize {
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 1rem;
  }
  .registerSection {
    width: 100%;
  }
  .inline-table12 {
    minwidth: '70px' !important;
    maxwidth: '70px' !important;
    overflow: 'auto' !important;
  }

  .inline-table {
    minwidth: '100px' !important;
    maxwidth: '100px' !important;
    overflow: 'auto';
  }
  .logo {
    margin-top: 50px !important;
  }
  .accordion {
    width: 105%;
  }
  .assigntask {
    font-family: 'Roboto Slab', serif;
    margin-bottom: 15px !important;
    margin-top: 25px !important;
    font-size: 24px !important;
    font-weight: 500;
    margin-left: 30px !important;
  }
  .readmoretask {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #fff;
    background-color: #163365;
    padding: 1px 5px;
    height: 32px;
    width: 55px;
    border-radius: 4px;
  }
  /* .sidebar .nav a {
    margin-left: -5px !important;
    text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
} */
  .logout-container {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: center;
  }
  h6 {
    margin-left: 10px !important ;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 12px !important;
  }
  .task-table-row {
    display: flex;
    justify-content: space-around;
    padding: 0px 0;
    margin: 15px -55px;
    border-bottom: 1px solid #ddd;
    align-items: baseline;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
  }
  .task-table-cell,
  .task-created-badge {
    font-size: 14px !important;
  }

  .task-table-column {
    font-size: 0.8rem;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
    margin: 10px;
  }
  .teammembers {
    margin: 4px;
  }
  .projectname {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
  }
  h5 .task-table-column {
    font-size: 0.75rem !important;
  }
  .task-table-header {
    width: 110%;
    padding: 0px 0px;
    font-weight: bold;
    text-align: center;
    background-color: #e6e6e6;
  }
  .table-container {
    margin-top: 0px !important;
    margin-left: -20px !important;
  }
  .AdminUsersSection {
    padding: 5px 0px 0px 15px;
  }
  .search-input {
    width: 100px;
  }
  .removemember {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removemember .fa-user-minus {
    color: white;
  }

  .removemember span {
    display: none; /* Hide text on small screens */
  }
  .ExtendDeadline {
    width: 98% !important;
    margin: 5px;
  }
  .d-flexu .downstream {
    display: flex !important;
    flex-direction: column !important;
  }
  .AddMembers {
    margin-left: 130px;
    margin-top: -30px;
    background-color: green; /* Green color for small screens */
    width: 40px; /* Make it circular */
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .AddMembers span {
    display: none; /* Hide the text on small screens */
  }

  .AddMembers .fa-user-plus {
    color: white; /* White icon for contrast */
  }

  .Add_Project {
    width: 100%;
  }
  .color-badge {
    height: 20px !important;
    padding: 4px !important;
  }
  .badge {
    padding: 10px;
    margin: -30px 0px -25px -60px;
  }
  .ml-2 {
    margin: 0px !important;
    text-align: justify;
    font: 14px;
  }
  .sidebar {
    height: 100% !important;
    width: 50px !important;
    padding: 15px !important;
  }
  .sidebar.open {
    height: 100% !important;
    width: 200px !important; /* Expandable width on small screens when open */
  }
  .col-7 {
    width: 100% !important;
  }
  .loginimg {
    display: none !important; /* Hide the image */
  }
  .registerimg {
    display: none !important;
  }
  .mx-3 {
    padding: 0px;
    width: 50%;
  }
  .header-left {
    /* remove all the overflow properties */
    /* make this change for universal */
    /* make button in 30%  */
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #d1d5db; /* Light grey background */
    padding: 0px 5px;
    border-radius: 5px;
    margin: 5px 5px;
  }
  .mp-4 {
    margin: 0px !important;
  }
  .list-group-item .badge {
    padding: 5px !important;
    margin: 5px !important;
    width: 140px !important;
  }
  .mb-4 {
    margin: 0.75rem 0.3rem 0.3rem 2.2rem !important;
  }

  .reports {
    margin-top: 0px !important;
  }
  .logout-container {
    margin-top: 75px !important;
    margin-left: -20px !important;
    padding-left: 0px !important;
  }
  .archived-button {
    margin-left: 50px !important;
    background-color: #1f3b5d !important; /* Dark blue color */
    color: #fff !important;
    border: none !important;
    padding: 5px 5px !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 11px !important;
    cursor: pointer !important;
  }

  .search-container {
    width: 150px !important;
    margin-left: --20px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    border: 1px solid #000 !important;
    padding: 12px 0px 12px 7px !important;
    border-radius: 5px !important;
    background-color: #fff !important;
  }
  .AdminUsers {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
  }

  .header {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
    background-color: #d1d5db !important; /* Light grey background */
    padding: 0px 5px !important;
    border-radius: 5px !important;
  }
  .AdminUsersSection h3 {
    margin-left: -40px !important;
    margin-bottom: 24px !important;
    font-family: 'Roboto Slab', serif !important;
    /* margin-bottom: 30px!important; */
    margin-top: 0 !important;
    text-align: center !important;
  }
  .sidebar .nav {
    display: block !important;
    list-style: none !important;
    /* padding-left: 7px !important; */
    padding-top: 15px !important;
    /* margin: 10px !important; */
  }
  .sidebar .nav a {
    text-decoration: none !important;
    color: #fff !important;
    display: flex !important;
    margin-left: -5px !important;
    flex-direction: row !important;
    width: 100% !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .sidebar .nav li {
    display: flex !important;
    height: 40px !important;
    padding: 0px 0px 0px 0px !important;
    align-items: center !important;
    gap: 10px !important;
    font-family: 'DM Sans' !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  /*.sidebar .nav {
    display: block!important;
    list-style: none!important;
    /* padding-left: 7px !important; 
    padding-top: 15px!important;
    /* margin: 10px!important; 
}*/
  .ForgotPasswordSection {
    margin: 10px !important;
    padding: 15px !important;
  }

  .forgotpassCss h2 {
    font-size: 22px !important;
  }

  .forgotpassCss input {
    font-size: 14px !important;
  }

  .forgotpassCss button {
    font-size: 14px !important;
  }
  .nav li a {
    display: block !important;
    padding: 0px 0px !important;
    text-decoration: none !important;
    color: white !important;
    background-color: #163365 !important; /* Default background */
    border-left: 5px solid transparent !important; /* Default border */
    transition: background-color 0.3s, border-left 0.3s !important;
    font-size: 16px !important; /* Adjust text size as needed */
    align-items: center !important;
  }
}
.nav-link {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'flex-start';
  padding: 0;
}

.addprojectlist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-left: 1em;
}

.addprojectlist::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #333;
  font-size: 1.2em;
}
.buttonscontainers {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
}
.ExtensionRequest:hover {
  background-color: #f26836;
}
.createticket {
  background-color: #1f3b5d; /* Dark blue color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
}
.createticket:hover {
  background-color: #1f3b5d;
}
.showticket {
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #f26836;
  margin-left: 30px;
}
.showticket:hover {
  background-color: #f26836;
}

.column1 {
  margin: 0px !important;
}
.dragdropcontainer {
  margin-left: 290px;
}
.todoscard {
  margin-left: 5px;
  margin-top: 20px;
  padding: 20px;
  width: 250px;
  border: 1px solid #000000 !important;
}
.responsive-table-container {
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents table cells from wrapping */
}
.responsive-table-container::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.avatar-group img {
  width: 32px;
  height: 32px;
  margin-left: -8px; /* Overlap effect */
  border-radius: 50%;
  border: 2px solid white;
}

.avatar-group div {
  margin-left: -8px;
  font-size: 12px;
}

button.btn-outline-secondary {
  background-color: white;
  color: black;
  border: none;
  font-weight: 500;
  /* margin-left: 100px; */
}

button.btn-outline-secondary .fa-angle-down {
  margin-left: auto;
}
.custom-padding-left {
  padding-left: 45px;
}
.select-wrapper .form-control {
  width: 100%;
}
.canceltodobutton {
  margin-left: 20%;
  width: 50%;
}
.savereminderbutton {
  margin-left: 1%;
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.todotable-header th {
  background-color: #e8f1ff !important;
}
.dropdown-menu.show {
  width: 72%;
}

@media (min-width: 992px) {
  .responsive-table-container {
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents table cells from wrapping */
  }
  .responsive-table-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .todoscard {
    /* width: 280px !important; */
    position: fixed;
  }
  .table-responsive {
    width: 60% !important;
    margin-left: 30px;
    scrollbar-width: none;
    /* padding-left: 30px; */
  }
  .table-responsive::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

/* Make the table scrollable on small screens */
.table-responsive {
  overflow-x: auto;
  scrollbar-width: none;
}
.table-responsive::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.column2 {
  margin-left: 5px !important;
}
.column2 {
  width: 70% !important;
}
/* Make the table scrollable on small screens */
.table-responsive {
  overflow-x: auto;
  scrollbar-width: none;
}
.table-responsive::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

/* Hide scrollbar but still allow horizontal scrolling on small screens */
@media (max-width: 767px) {
  .responsive-table-container {
    margin-left: 30px;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents table cells from wrapping */
  }
  .responsive-table-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .responsive-table-container {
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents table cells from wrapping */
    margin-left: 30px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    /* overflow-x: auto; */
    display: block;
    /* margin-left: 30px; */
  }

  .table {
    min-width: 600px; /* Adjust this width to your table's minimum desired width */
  }

  /* Hide the scrollbar in WebKit browsers */

  /* For Firefox (which uses a different scrollbar implementation), you can also hide the scrollbar */
  .table-responsive {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
}
.table-responsive::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

@media (max-width: 767px) {
  .responsive-table-container {
    margin-left: 30px;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents table cells from wrapping */
  }
  .responsive-table-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .todoscard {
    width: 280px !important;
    position: fixed;
  }

  .column2 {
    margin-left: auto !important;
  }
}
.responsive-container {
  max-width: 100%; /* Ensures container is within the viewport width */
  margin: 0 auto; /* Centers the content */
  padding: 10px; /* Adds padding for a better look */
  overflow-x: auto; /* Enables horizontal scrolling on small screens */
}

.column1,
.column2 {
  margin: 0 auto; /* Centers the columns */
}

@media (max-width: 576px) {
  .responsive-table-container {
    margin-left: 30px;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents table cells from wrapping */
  }
  .responsive-table-container::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  .column2 {
    max-width: 100%;
    overflow-x: auto;
  }
}
.table-responsive {
  overflow-x: visible !important;
  -webkit-overflow-scrolling: touch;
}
.table-responsive::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.custom-select-dropdown {
  padding-right: 30px; /* Make space for the dropdown arrow */
  position: relative;
}

/* Add dropdown arrow icon */
.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 42;
  transform: translateY(-260%);
  pointer-events: none;
  color: #aaa; /* Light color for the icon */
}
.dropdown-icons {
  position: absolute;
  right: 32px;
  top: 61%;
  transform: translateY(50%);
  pointer-events: none;
  color: #aaa;
}
.ticket-info {
  padding: 15px;
}
.col-md-9 {
  overflow-x: hidden; /* Hides horizontal scrollbar */
  scrollbar-width: none; /* For Firefox */
}

.col-md-9::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
/* Ensure the ticket raise form has consistent styling */
.ticketraiseform {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bg-white {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Ensure form doesn't stretch too wide */
}

/* Style the form and inputs for responsiveness */
form {
  display: flex;
  flex-direction: column;
}

.form-control,
.form-select,
textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* button:hover {
  background-color: #007bff;
} */

button:focus {
  outline: none;
  border-color: #0056b3;
}

label {
  font-size: 1rem;
  font-weight: 500;
}

/* Responsive handling for the container when size decreases */
@media (max-width: 768px) {
  .ticketraiseform {
    padding: 15px;
  }

  .bg-white {
    padding: 15px;
    width: 100%;
  }

  .form-control,
  .form-select,
  textarea {
    font-size: 14px;
    padding: 0.5rem;
  }

  button {
    font-size: 14px;
    padding: 8px 12px;
  }
}

/* Handle small form (after submission or when toggled) */

form select,
form input[type='file'],
form textarea {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.form-label {
  margin-bottom: 5px;
}

/* Custom styles for the file input */
input[type='file'] {
  padding: 6px;
  font-size: 14px;
  cursor: pointer;
}
.see-more-btn {
  background-color: #fff;
  border: none;
  color: #007bff;
}
.see-more-btn:hover {
  background-color: #fff;
}
.todosbuttonscontainer {
  display: flex;
  flex-direction: column;
}
/* .content{
  margin-left: 225px;
} */
.read-more-btn {
  border: none !important;
  color: #007bff;
  background-color: #fff;
}
.read-more-btn:hover {
  background-color: #fff;
  color: #007bff;
}
.read-more-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5; /* Adjust based on your design */
  height: auto;
}

.read-more-expanded {
  -webkit-line-clamp: unset; /* Remove line limit */
  overflow: visible; /* Show full text */
}

.read-more-toggle {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 14px;
}
/* Fullscreen modal with two-column grid layout */
/* .fullscreen-modal .modal-dialog {
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
} */

.fullscreen-modal .modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fullscreen-modal .modal-header,
.fullscreen-modal .modal-footer {
  border: none;
  padding: 0;
}

.fullscreen-modal .modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: grid;

  gap: 16px;
  box-sizing: border-box;
}

.fullscreen-modal .modal-body table {
  width: 100%;
  border-collapse: collapse;
}

.fullscreen-modal .modal-body td {
  padding: 8px;
  vertical-align: top;
  border-bottom: 1px solid #ddd;
}

.fullscreen-modal .modal-body td strong {
  color: #333;
}

/* Adjusting the modal content for smaller screens */
@media (max-width: 768px) {
  .fullscreen-modal .modal-body {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }
}
.content-shifted {
  margin-left: 80px; /* or adjust as necessary to match the minimized navbar width */
}

.content-expanded {
  margin-left: 250px; /* default or expanded navbar width */
}
/* Make the modal take full width */
.full-width-modal .modal-dialog {
  width: 100vw; /* 100% of the viewport width */
  max-width: 100vw; /* Ensure it doesn't exceed the viewport width */
  margin: 0; /* Remove the default margin */
}

/* Optional: make the modal body take the full height too */
.full-width-modal .modal-body {
  max-height: 90vh; /* Adjust as needed, keeps it within the visible viewport */
  overflow-y: auto; /* Allow scrolling if content exceeds height */
}
/* Full Screen Modal */
.full-screen-modal .modal-dialog {
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 !important; /* Remove margin for full-screen effect */
}

/* Basic styles for the modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.tableFixHead2 tbody tr:hover {
  background-color: #f0f0f0; /* Change this to any color you want */
  cursor: pointer;
}
.removemembers {
  font-family: 'Roboto';
  list-style: none;
  padding: 0;
  margin: 0;
}

.removemembers li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Space between items */
}

.removemember {
  margin-left: auto; /* Push button to the right */
}
.notifications-header {
  display: flex;
  justify-content: space-between; /* Space between title and close button */
  align-items: center;
  padding: 10px;
}

.notifications-header button {
  background: none;
  border: none;
  color: #333;
  font-size: 20px;
  cursor: pointer;
}

.raiseticketsubmit {
  background-color: #1f3b5d;
  color: #fff;
  width: 100%;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #1f3b5d;
}
.raiseticketsubmit:hover {
  background-color: #1f3b5d;
}

/* pirchart file css */

.dashboard {
  padding: 0px 4px;
}
.project-list {
  gap: 24px;
  display: flex;
  flex-direction: row;
}
.project-list select {
  width: 285px;
  margin-bottom: 38px;
  height: 50px;
  background-color: #fff;
  font-size: 14px;
  font-family: 'Roboto';
}
.project-status p {
  font-size: 18px !important;
  font-weight: 600;
}
.dashboard-grid {
  /* display: flex; */
  gap: 20px;
  flex-wrap: wrap; /* Allow the columns to wrap in smaller screens */
}

.project-status {
  display: flex;
  flex-direction: row;
  /* height: 350px; */
}

.assignee-task {
  flex: 1;
}

.project-status h4,
.assignee-task h4 {
  gap: 5px;
  display: flex;
  margin-bottom: 20px;
  font-family: 'Roboto';
}

/* New Task Overview Section */
.task-overview {
  flex: 1;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-overview h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.overview-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.overview-item h5 {
  font-size: 16px;
  font-weight: normal;
}

.overview-item p {
  font-size: 16px;
  font-weight: bold;
}

/* Keep the existing styles for the status-list and other components */
.status-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.status-list li {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  font-family: 'Roboto';
}

.status-list .square {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
}

.backlog .square {
  background-color: #0065ff;
}

.in-progress .square {
  background-color: #ffab00;
}

.in-review .square {
  background-color: #00b0dd;
}

.qa .square {
  background-color: #ff5630;
}
.Done .square {
  background-color: #4caf50;
}

.assignees img {
  margin-right: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 50px;
}

.assignees img:hover {
  outline: 2px solid #fff;
}

.assignee-task h2,
p {
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 20px;
}

.assignee-task table th {
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #000;
}

.assignee-task tbody tr td {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #000;
}

.assignee-task tbody tr td {
  margin-bottom: 10px !important;
}

.statusinfo,
.statusinfo1 {
  gap: 30px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
}

.statusinfo h5 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-family: 'Roboto';
}

.statusinfo p {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-family: 'Roboto';
  padding: 0px;
  margin-left: 60px;
}

.statusinfo1 p {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  font-family: 'Roboto';
  padding: 0px;
  margin-left: 60px;
}

.tasks {
  color: #000;
  font-size: 18px;
  font-family: 'Roboto';
}

.project-status-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 20px;
}

.pietables {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

/* Each column styling */
.project-status-column {
  flex: 1;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.projectstatus p {
  align-items: center !important;
  margin-top: 70px;
  margin-bottom: 0px !important;
}
.projectstatus {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pielines,
.statusline {
  width: 277px;
  background-color: #cccc;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
}

/* Container styling */
.image-container {
  display: flex;
  gap: 15px;
  margin-left: 20px;
  /* align-items: center; */
}

/* Card styling */
.image-card {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 63px;
  margin-left: 15px;
  position: relative;
  text-align: center;
  transition: transform 0.3s ease;
}

/* Image wrapper (circle background) */
.image-wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 0 rgba(0, 0, 255, 0);
}

.image-card.selected .image-wrapper {
  transform: scale(1.5);
  background-color: rgba(0, 0, 255, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.4);
}

.image-card:not(.selected) .image-wrapper {
  transform: scale(0.8);
}

/* Image styling */
.profile-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.image-card.selected .profile-image {
  transform: scale(0.7);
}

.image-card:not(.selected) .profile-image {
  transform: scale(0.8);
}

/* Name overlay styling */
.name-overlay {
  margin-right: 0px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-card.selected .name-overlay,
.name-overlay.visible {
  opacity: 1;
}
.project-table-container {
  /* margin-top: 99px; */
  padding: 20px;
  width: 92%;
  background: #fff;
  /* margin-left: 58px; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-table-title {
  font-size: 18px;
  font-weight: bold;
  /* margin-bottom: 15px; */
  font-family: 'Roboto';
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  font-family: 'Roboto';
  background-color: #f5f9ff;
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.custom-table td {
  padding: 10px;
  border-top: 1px solid #ddd;
  vertical-align: top;
}

.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.task-status {
  color: #888;
  font-size: 12px;
}
/* AdminTaskList.css */
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-width: 0;
}

/* Projectadmin dashboard.css  */

.dashboard-container {
  display: flex !important;
  flex-direction: column !important;
}
.dashboard-container .col-md-4 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
/* .col-md-4{
  width: 475px;
  height: 801px;
  margin-top: 50px;
  margin-left: 333px;
} */
.dashboard-container .col-md-4 button {
  width: 392px;
  height: 50px;
}
.abc {
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 426px;
  height: 50px;
  margin: 10px 20px;
}
.pqr {
  background-color: #f26836;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}
.pqr:hover {
  background-color: #f26836;
}
.ProList {
  width: 426px;
  height: 50px;
  margin: 10px 20px;
}
.ProStatus {
  width: 426px;
  height: 20px;
  margin: 10px 20px;
}
.ProDuration {
  width: 426px;
  height: 20px;
  margin: 10px 20px;
}
.ToTask {
  width: 426px;
  height: 20px;
  margin: 10px 20px;
}
.canvas {
  box-sizing: border-box;
  display: block;
  height: 207px;
  /* width: 456px; */
  margin-left: 20px;
}
.efg {
  width: 426px;
  height: 50px;
  margin: 10px 20px;
}
.atl {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  width: 426px;
  height: 50px;
}
.iop {
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: row;
  margin: 20px 20px;
  justify-content: unset;
}
.AddTeamMember {
  background-color: #1b2d50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 205px;
  height: 50px;
}
.AddTeamMember:hover {
  background-color: #1b2d50;
}
/* .Nouser {
  margin-left: 20px;
} */
.datarepre {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.status-list {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px; /* Space between rows and columns */
  list-style: none;
  padding: 0;
  margin: 0;
  width: 0%; /* Adjust as per your container width */
}

.status-list li {
  width: 135px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  /* justify-content: space-between; */
}

.status-list .square {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
}

.backlog .square {
  background-color: #0065ff;
}

.in-progress .square {
  background-color: #ffab00;
}

.in-review .square {
  background-color: #00b0dd;
}

.qa .square {
  background-color: #ff5630;
}

.numericnumber {
  margin-left: 10px;
}
.btn-close:hover {
}
.closebuttons {
  width: 10% !important;
}
.modal-footer {
  display: block !important;
}
/* .imagelogo{
     display: flex!important;
     justify-content: center!important;
     align-items: center!important;
     margin-top: 50px!important;
} */
.modal-title {
  width: 427px !important;
}
.canclerequest {
  align-items: right;
  width: 3px !important;
  /* margin-bottom: 34px!important; */
}

.modal-container {
  max-width: 500px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.modal-header {
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #999;
}

.modal-body {
  padding: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #007bff;
}

.form-actions {
  text-align: right;
}

.submit-button {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.submit-button:hover {
  background-color: #0056b3;
}

/*  */
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures proper spacing between elements */
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.canclerequest {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #999;
}

.modal-title {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  flex-grow: 1; /* Pushes the title to take available space */
  text-align: center;
}
/* General styling */
.task-history-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.task-history-list {
  list-style-type: none;
  padding: 0;
}

.task-history-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-history-item strong {
  font-weight: 700;
  color: #333;
}

.task-history-change {
  margin-top: 5px;
  font-size: 0.9rem;
  color: #555;
}

.task-history-change.added {
  color: green;
}

.task-history-change.removed {
  color: red;
}

.no-history {
  font-size: 1.1rem;
  color: #888;
}

/* Date and time styling */
.task-history-item time {
  font-size: 0.85rem;
  color: #888;
}

.task-history-item .elapsed-time {
  font-size: 0.85rem;
  color: #666;
  font-style: italic;
}

.uptask {
  background-color: #1b2d50;
  color: white;
  width: 30%;
  border-radius: 5px;
}
.viewmore {
  background-color: #f26836;
  color: white;
  border-radius: 5px;
  border-color: transparent;
}
.notes {
  margin-top: 20px;
}
/* Responsive Design */
@media (max-width: 768px) {
  .task-history-title {
    font-size: 1.2rem;
  }

  .task-history-item {
    padding: 10px;
    margin-bottom: 8px;
  }

  .task-history-item strong {
    font-size: 1rem;
  }

  .task-history-change {
    font-size: 0.85rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .canvas {
    width: 375px;
  }
  .seemorebtn {
    /* width: 100%; */
    text-align: right;
    margin: 15px 30px 10px 10px;
}
  .content {
    overflow-x: clip;
    margin-left: 100px;
    padding: 20px;
    /* overflow-y: auto; */
    width: calc(100% - 250px);
    min-height: 100vh;
  }

  .iop {
    /* display: inline;
    margin-left: 60px;
    float: left; */
    width: 100%;
    bottom: 12px;
    left: 7%;
    position: relative;
    display: inline;
  }

  .addateamlead {
    margin-left: 0px;
  }

  .atl {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 57px;
    margin-right: 20px;
    width: 426px;
    height: 50px;
  }
  .Nouser {
    margin-left: 10px;
  }
  /* .image-container {
    display: flex;
    gap: 15px;
    margin-left: 55px;
  } */
  .image-container {
    display: flex;
    gap: 15px;
    margin-left: 58px;
    overflow: scroll;
    padding-top: 14px;
  }
  .project-table-container {
    padding: 20px;
    width: 92%;
    background: #fff;
    margin-left: 89px;
    height: 380px; /* Let the height adjust naturally */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .custom-table {
    width: 100%;
    /* max-height: 380px; */
    border-collapse: collapse;
  }

  .custom-table tbody {
    max-height: 380px;
    display: block; /* Make the tbody scrollable */
    max-height: 281px; /* Limit the scrollable area height */
    overflow-y: scroll; /* Enable vertical scrolling */
  }

  .custom-table thead,
  .custom-table tbody tr {
    display: table; /* Retain table layout for rows */
    width: 100%; /* Ensure rows span the full width */
    table-layout: fixed; /* Prevent columns from stretching */
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .open + .content .iop {
    left: 27%;
  }

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 79%;
    background: #fff;
    margin-left: 151px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open + .content .iop {
    left: 15%;
  }

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 81%;
    background: #fff;
    margin-left: 117px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open + .content .image-container {
    margin-left: 107px;
  }

  .open + .content .atl {
    margin-left: 106px;
  }
  /* width: max-content; */
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .canvas {
    width: 375px;
  }
  .seemorebtn {
    width: 100%;
    text-align: right;
    margin: 15px 4px 10px -16px !important;
}

  .iop {
    /* display: inline;
    margin-left: 60px;
    float: left; */
    width: 100%;
    bottom: 12px;
    left: 7%;
    position: relative;
    display: inline;
  }

  .addateamlead {
    margin-left: 0px;
  }

  .atl {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 57px;
    margin-right: 20px;
    width: 426px;
    height: 50px;
  }
  .Nouser {
    margin-left: 64px;
  }
  .image-container {
    display: flex;
    gap: 15px;
    margin-left: 90px;
    overflow: scroll;
    padding-top: 14px;
  }

  /* .project-table-container {
    /* margin-top: 99px;
    padding: 20px;
    width: 92%;
    background: #fff;
    margin-left: 89px;
    height: 281px;
    overflow-y: scroll;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  } */
  .project-table-container {
    padding: 20px;
    width: 92%;
    background: #fff;
    margin-left: 89px;
    height: 439px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

  .custom-table {
    width: 100%;
    /* max-height: 380px; */
    border-collapse: collapse;
  }

  .custom-table tbody {
    max-height: 380px;
    display: block; /* Make the tbody scrollable */
    max-height: 281px; /* Limit the scrollable area height */
    overflow-y: scroll; /* Enable vertical scrolling */
  }

  .custom-table thead,
  .custom-table tbody tr {
    display: table; /* Retain table layout for rows */
    width: 100%; /* Ensure rows span the full width */
    table-layout: fixed; /* Prevent columns from stretching */
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .open + .content .iop {
    left: 27%;
  }

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 79%;
    background: #fff;
    margin-left: 151px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open + .content .image-container {
    padding-top: 14px;
    margin-left: 147px;
    overflow: scroll;
  }

  .open + .content .atl {
    margin-left: 164px;
  }
  .dashboard-container {
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden;
  }
  .name-overlay {
    padding-bottom: 10px;
    margin-right: 0px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .iop {
    width: 100%;
    bottom: 12px;
    left: 15%;
    position: relative;
    display: inline;
  }
  .atl {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 107px;
    margin-right: 20px;
    width: 426px;
    height: 50px;
  }
}
@media screen and (min-width: 1501px) and (max-width: 1899px) {
  .canvas {
    width: 375px;
  }

  .iop {
    /* display: inline;
    margin-left: 60px;
    float: left; */
    width: 100%;
    bottom: 12px;
    left: 6%;
    position: relative;
    display: inline;
  }

  .addateamlead {
    margin-left: 0px;
  }

  .atl {
    margin-top: 10px;
    margin-bottom: -1px;
    margin-left: 130px;
    margin-right: 20px;
    width: 426px;
    height: 50px;
  }
  .Nouser {
    margin-left: 14px;
  }
  .image-container {
    display: flex;
    gap: 15px;
    margin-left: 55px;
  }
  .project-table-container {
    padding: 20px;
    width: 90%;
    background: #fff;
    margin-left: 113px;
    height: 380px; /* Let the height adjust naturally */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .custom-table {
    width: 100%;
    /* max-height: 380px; */
    border-collapse: collapse;
  }

  .custom-table tbody {
    max-height: 380px;
    display: block; /* Make the tbody scrollable */
    max-height: 281px; /* Limit the scrollable area height */
    overflow-y: scroll; /* Enable vertical scrolling */
  }

  .custom-table thead,
  .custom-table tbody tr {
    display: table; /* Retain table layout for rows */
    width: 100%; /* Ensure rows span the full width */
    table-layout: fixed; /* Prevent columns from stretching */
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .open + .content .iop {
    left: 27%;
  }

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 79%;
    background: #fff;
    margin-left: 151px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open + .content .iop {
    left: 15%;
  }

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 81%;
    background: #fff;
    margin-left: 117px !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .open + .content .image-container {
    margin-left: 125px;
  }

  .open + .content .atl {
    margin-left: 130px;
  }
  .logout-container {
    /* margin-top: 200px; */
    /* margin-left: -12%; */
    padding-left: 30px;
  }

  .logo {
    margin-top: 10px !important;
  }
  .open + .content .image-container {
    padding-right: 86px;
    padding-top: 20px;
    margin-left: -5px;
    overflow: scroll;
    column-gap: 5px;
  }
  .ProDuration {
    width: 426px;
    height: 9px;
    margin: 10px 20px;
  }
  .ProStatus {
    width: 426px;
    height: 0px;
    margin: 10px 20px;
  }
  .iop {
    width: 100%;
    bottom: 12px;
    left: 6%;
    position: static;
    display: inline;
  }
  .image-container {
    padding-top: 13px;
    display: flex;
    gap: 15px;
    overflow: scroll;
    margin-left: -9px;
  }
  .assignee-task {
    margin-left: 130px;
  }
}
@media screen and (min-width: 1900px) and (max-width: 2600px) {
  /* .canvas {
    width: 375px;
  } */

  .seemorebtn {
    /* height: 134%; */
    width: 100%;
    text-align: right;
    margin: 15px 30px 10px 1px!important;
}
  .image-card {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 63px;
    margin-left: 15px;
    position: relative;
    text-align: center;
    transition: transform 0.3s ease;
    margin-top: 20px;
  }
  .canvas {
    box-sizing: border-box;
    display: block;
    height: 326px;
    width: 456px;
    margin-left: 20px;
  }

  .iop {
    margin-top: 30px;
    width: 80%;
    bottom: 12px;
    left: 3%;
    position: relative;
    /* display: inline; */
  }

  .addateamlead {
    margin-left: 0px;
  }

  .atl {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 57px;
    margin-right: 20px;
    width: 426px;
    height: 50px;
  }
  .Nouser {
    margin-left: 10px;
  }
  .image-container {
    overflow: visible;
    display: flex;
    gap: 15px;
    margin-left: 55px;
  }
  .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 77%;
    background: #fff;
    margin-left: 48px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* .open + .content .iop {
    left: 15%;
  } */

  .open + .content .project-table-container {
    /* margin-top: 99px; */
    padding: 20px;
    width: 81%;
    background: #fff;
    /* margin-left: 117px !important; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* .open + .content .image-container {
    margin-left: 107px;
  } */

  /* .open + .content .atl {
    margin-left: 106px;
  } */
}
.seemorebtn {
  width: 100%;
  text-align: right;
  margin: 15px 30px 10px 10px;
}
